<template>
  <ion-page id="page-content">
    <ion-content :fullscreen="true">
      <topbar
        :bigtitle="true"
        title="Notification"
        :showBack="true"
      ></topbar>
      <ul class="noti-list" v-if="notiList.length > 0">
        <li
          v-for="(item, index) in notiList"
          :key="'noti' + index"
          @click="goDetail"
        >
          <span class="is-new"></span>
          <div class="img-box">
            <img src="../assets/noti.png" v-if="item.type == 1" />
            <img src="../assets/reward.png" v-if="item.type == 2" />
            <img src="../assets/estamp.png" v-if="item.type == 3" />
            <img src="../assets/friends.png" v-if="item.type == 4" />
            <img src="../assets/collectorcard.png" v-if="item.type == 5" />
            <img src="../assets/transaction.png" v-if="item.type == 6" />
          </div>
          <div class="detail">
            <p class="font-12 sub-color font-b m-ver-7">
              <span v-if="item.type == 1">SYSTEM MESSAGE</span>
              <span v-if="item.type == 2">OFFER NEWS</span>
              <span v-if="item.type == 3">ESTAMPS COLLECTED </span>
              <span v-if="item.type == 4">FRIENDS REFFERAL</span>
              <span v-if="item.type == 5">COLLECTOR CARD</span>
              <span v-if="item.type == 6">TRANSATION UPDATED</span>
            </p>
            <p class="font-18 main-color font-m m-b-20">
              {{ item.title }}
            </p>
            <p class="font-14 gery-color">
              {{ item.date }}
            </p>
          </div>
        </li>
      </ul>
      <div class="no-date" v-else>
        <img class="no-noti-img" src="../assets/no_noti.png" />
        <p class="font-16 label-color">No notification</p>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent } from "@ionic/vue";
import topbar from "@/components/topbar.vue";
import config from "../config";
export default {
  name: "notificationPage",
  mixins: [config.globalMixin],
  components: { IonContent, IonPage, topbar },
  data() {
    return {
      notiList: [
        {
          type: 1,
          title: "Hello LKF Cool Japan! Come and enjoy your Novmeber offers",
          date: "2019-04-10	01:32 pm",
        },
        {
          type: 2,
          title: "Hello LKF Cool Japan! Come and enjoy your Novmeber offers",
          date: "2019-04-10	01:32 pm",
        },
        {
          type: 3,
          title: "Hello LKF Cool Japan! Come and enjoy your Novmeber offers",
          date: "2019-04-10	01:32 pm",
        },
        {
          type: 4,
          title: "Hello LKF Cool Japan! Come and enjoy your Novmeber offers",
          date: "2019-04-10	01:32 pm",
        },
        {
          type: 5,
          title: "Hello LKF Cool Japan! Come and enjoy your Novmeber offers",
          date: "2019-04-10	01:32 pm",
        },
        {
          type: 6,
          title: "Hello LKF Cool Japan! Come and enjoy your Novmeber offers",
          date: "2019-04-10	01:32 pm",
        },
      ],
    };
  },
  methods: {
    backEvent() {
      this.$router.push({
        path: "/main/landing",
      });
    },
    goDetail() {
      this.$router.push({
        path: "/notificationDetail",
      });
    },
  },
};
</script>